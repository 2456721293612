.slider-container{
    min-height: 0;
    min-width: 100%;
}
.slider-container button{
    display: none !important;
}
.main_carocontens{
    background-color: #fff;
    border-radius: 20px;
    padding: 30px 20px;
    margin: 30px 0px;
    box-shadow: 0px 4px 18px 0px #00000040;
    min-height: 40vh;
    justify-content: center;
}
.main_carocontens img{
    /* width: 15%; */
    width: calc(50px + 5%);
    margin-bottom: 30px;
}
.main_carocontens span{
    color: #7F7F7F;
    margin-bottom: 30px;
}
.main_carocontens h4{
    color: #000;
    margin-bottom:0px;
    font-weight: 600;
}
.main_carocontens small{
    color: #7F7F7F;
    margin-bottom:0px;
    font-weight: 600;
    font-size: small;
}
.button-container button{
    width: max-content !important;
    border: none;
    background-color: transparent;
    margin: 10px;
}
.button-container button img{
    width: 35px;
}