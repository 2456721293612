.termsconditionmain{
    background-color: #fff;
    padding: 30px 0px;
}
.termsconditionmain img{
    width: 145px;
    margin-bottom: 20px;
    cursor: pointer;
}
.termsconditionmain h2{
    color:#000;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 20px;
}
.termsconditionmain h5{
    color:#000;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 20px;
}
.termsconditionmain small{
    color:#000;
    font-weight: 500;
    margin-bottom: 20px;
}
.termsconditionmain span{
    width: 100%;
    font-size: small;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #000;
    margin-top: 20px;
}
.termsconditionmain span input{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.termsconditionmain button{
    width: 170px;
    text-align: center;
    background-color: #C32A7C;
    border: 1px solid #C32A7C;
    color: #fff;
    font-weight:600 ;
    margin: 20px 0px;
    border-radius: 100px;
    height: 40px;
}
.disabled_btns{
    opacity: 0.7;
}
.termsconditionmain label{
    display: flex;
    align-items: center;
}
.termsconditionmain label span{
    width: max-content !important;
    color: #ff0000;
    margin-top: 0px !important;
    font-size: medium;
    margin-left: 5px;
}