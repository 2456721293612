@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@font-face {
  font-family: calibri;
  src: url(./assets/calibri-font-family/calibri-regular.ttf);
}
.firstlett_caps::first-letter {
  text-transform: uppercase !important;
}
.caliberifontsset{
  font-family: calibri !important;
}
.caliberifontsset td{
  padding: 10px;
  border: 1px solid;
}
.caliberifontsset table{
  width: 100%;
}
body{
  font-family: "Montserrat", sans-serif !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(./assets/calendar.svg) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  cursor: pointer;
}
/* Fixes */
.mario_fixers{
  min-height: 100px !important;
}
.mario_fixers_article{
  min-height: 60px !important;
}
.red_starimpo{
  color: red;
  margin-left: 5px;
  font-size: medium;
}
/* Effects */
.admintab_btnredirect .active_tabbtn{
  background-color: #1A5298 !important;
  color: #fff !important;
}
.knowledgetab_btnredirect .active_tabbtn{
  background-color: #1A5298 !important;
  color: #fff !important;
}
input,textarea{
  box-shadow: 0px 5px 1px 0px #00000026 inset;
}
input, button, textarea,select{
  transition: 0.4s;
}
.value_inputfilter{
  outline-color: #C32A7C !important;
}
input:focus,textarea:focus,.jobseekersearch:hover,.date_pickermux .Mui-focused input{
  box-shadow: 0px 0px 12px 0px #C32A7C52 !important;
  background-color: #fff !important;
}
.select__multi-value{
  background-color: #fff !important;
  border: 1px solid #C32A7C;
}
select:focus{
  box-shadow: 0px 35px 17.9px 0px #00000017 inset !important;
  box-shadow: 0px 0px 12px 0px #C32A7C52 !important;
  background-color: #fff !important;
  border: 1px solid #C32A7C !important;
}
select{
  /* box-shadow: 0px 35px 17.9px 0px #00000017 inset; */
  box-shadow: 0px 23px 12.9px 1px #00000017 inset;
  background-color: #fff;
}
select:hover{
  background-color: #FFF3F9;
  box-shadow:none;
}
.datatable_jobpagi .Mui-focused .MuiOutlinedInput-notchedOutline{
  box-shadow: 0px 0px 12px 0px #C32A7C52 !important;
  border: 1px solid #C32A7C !important;
  box-shadow: 0px 35px 17.9px 0px #00000017 inset !important;
}
.datatable_jobpagi .MuiFormControl-root{
  min-width: 200px;
  box-shadow: 0px 35px 17.9px 0px #00000017 inset;
}
.datatable_jobpagi .MuiFormControl-root:hover{
  min-width: 200px;
  background-color: #FFF3F9;
  box-shadow: none;
}
.date_pickermux .Mui-focused input{
  box-shadow: 0px 0px 12px 0px #C32A7C52 !important;
  background-color: #fff !important;
  border: 1px solid #C32A7C !important;
}
input:hover,textarea:hover,.date_pickermux:hover .MuiOutlinedInput-root {
  background-color: #FFF3F9 !important;
}
.select__control:hover{
  background-color: #FFF3F9;
}
.jobseekersearch{
  transition: 0.4s;
}
.jobseekersearch input:focus,input[type="radio"],input[type="checkbox"]{
  box-shadow: none !important;
  background-color: none;
}
.datatable_jobpagi .select_withsearch{
  z-index: 7;
}
.select_withsearch input:focus {
  box-shadow: none !important;
}
.select__control{
  box-shadow: 0px 35px 17.9px 0px #00000017 inset;
}
.select__control--is-focused{
  box-shadow: 0px 35px 17.9px 0px #00000017 inset !important;
  box-shadow: 0px 0px 12px 0px #C32A7C52 !important;
  background-color: #fff !important;
  border: 2px solid #C32A7C !important;
  outline-color: #C32A7C !important;
}
.select__control--is-focused input, .select__input{
  box-shadow: none !important;
  background-color: transparent !important;
}
button:hover{
  transition: 0.4s;
}
.date_pickermux .Mui-disabled{
  background-color: #dddddd !important;
  -webkit-text-fill-color:unset !important;
}

input:disabled,.select__control--is-disabled {
  background-color: #dddddd !important;
}
.select__single-value--is-disabled{
  color: #000 !important;
}
.drafttabs_tabs .nav-item button:hover,.knowledgetab_btnredirect button:hover{
  background-color: #DEECFF;
  color: #1A5298;
}
.admintab_btnredirect button:hover{
  background-color: #DEECFF;
  color: #1A5298;
}
.submit_btn img{
  width: 40px !important;
}
.registersendbtn2:hover,.submit_btn:hover,.cancel_btnbonly:hover,.save_btnbonly:hover,.border_btnpink:hover,.bgpink_btnpink:hover,.blue_primarymodalbtn2:hover,.blue_primarymodalbtn1:hover{
  background-color: #FFE8F4;
  color: #C32A7C;
}
.registersendbtn1:hover{
  background-color: #FFE8F4;
  color: #C32A7C;
}
.show_filterbtn:hover,.download_csvbtndatatable:hover{
  background-color: #DEECFF !important;
  color: #1A5298 !important;
  border: 1px solid #1A5298;
}
.filterremovebtn:hover,.filteraddbtn:hover{
  background-color: #FFE8F4 !important;
  color: #C32A7C !important;
}
.small_adsbtn:hover,.modal_delete:hover,.modal_cancel:hover,.suspent_btn:hover{
  background-color: #FFE8F4;
  color: #C32A7C;
}
.gropadmingridcont button:hover,.tab_membersets button:hover{
  background-color: transparent;
  color: #C32A7C;
  border: 1px solid #C32A7C;
}
.header_main .login_btn:hover{
  color: #1A5298;
  font-weight: 800;
}
.newadderbtn:hover,.jobseekersearch button:hover,.left_inputlogin button:hover,.termsconditionmain button:hover,.header_main .register_btn:hover{
  background-color: #FFE8F4 !important;
  color: #C32A7C !important;
  border: 1px solid #C32A7C;
}
.notification_btn:hover svg{
  animation: bell-vibrate 0.3s ease-in-out 2;
}

@keyframes bell-vibrate {  
  0%, 100% { transform: rotate(0); }
  25% { transform: rotate(-15deg); }
  50% { transform: rotate(15deg); }
  75% { transform: rotate(-10deg); }
}

.list_showers small:hover{
  background-color: #FFE8F4;
  color: #C32A7C;
}
.list_showers .sidebaraccords:hover{
  background-color: transparent;
} 
.sidebaraccords .accordion-body small:hover{
  background-color: #FFE8F4;
  color: #C32A7C;
}


.MuiDataGrid-row:nth-child(even){
  background-color: #deecff6f;
}
.MuiDataGrid-row:nth-child(odd){
  background-color: #fff;
}
.MuiDataGrid-columnHeader{
  background-color: #F8F8F8;
}
/* select option:hover{
  background-color: #C32A7C !important;
} */
