.header_main{
    padding: 15px 5vw !important;
    position: sticky;
    top: 0px;
    background-color: #fff;
    z-index: 99;
}
.header_main img{
    width: 100%;
    cursor: pointer;
}
.header_main .login_btn{
    width: max-content !important;
    height: 35px ;
    padding: 0px 20px;
    background-color: transparent;
    line-height: 0px;
    text-transform: capitalize;
    color: #000;
    font-weight: 500;
    font-family: "Montserrat", sans-serif !important;
}
.header_main .register_btn{
    width: max-content !important;
    height: 35px;
    padding: 0px 20px ;
    background-color: #C32A7C;
    line-height: 0px;
    text-transform: capitalize;
    color: #fff;
    border-radius: 100px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif !important;
}
@media only screen and (min-width: 10px) and (max-width: 500px){
    .header_main .login_btn{
        font-size: small;
        padding: 0px 10px;
        height: 30px;
    }
    .header_main .register_btn{
        font-size: small;
        padding: 0px 15px;
        height: 30px;
    }
}