.hero_section{
    background-color: #fff;
    min-height: 90vh;
    position: relative;
}
.hero_section h1{
    text-align: center;
    color: #C32A7C;
    font-weight: 600;
    margin-bottom: 30px;
}
.hero_section h3{
    text-align: center;
    color: #000;
    font-weight: 600;
}
.hero_section span{
    text-align: center;
    color: #7F7F7F;
    font-weight: 400;
    width: 60%;
}
.hero_section button:hover::before {
    transform: rotateX(90deg);
    background-color: #C32A7C;
}
.hero_section button:hover::after {
    transform: rotateY(90deg);
    background-color: #C32A7C;
}
.hero_section button::after {
    width: 100%;
    height: 100%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    background:#fff;
    transition: all 0.52s;
    z-index: -1;
    border-radius: 5px;
}
.hero_section button::before {
    width: 100%;
    height: 100%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    background: #fff;
    transition: all 0.52s;
    z-index: -1;
    border-radius: 5px;
}
.hero_section button:hover {
    color: #fff;
    background-color: #C32A7C;
}
.hero_section button{
    position: relative;
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    width: 146px;
    height: 40px;
    border: 1.5px solid #C32A7C;
    background-color: transparent;
    color: #C32A7C;
    margin-top: 30px;
    border-radius: 100px;
    font-weight: 500;
    font-size: 14px;
}
.hero_float1{
    position: absolute;
    left: 10%;
    top: 5%;
    width: calc(80px + 5%);
}
.hero_float2{
    position: absolute;
    right: 10%;
    top: 5%;
    width: calc(80px + 6%);
}
.hero_float3{
    position: absolute;
    left: 3%;
    /* width: 10%; */
    top: 40%;
    width: calc(80px + 5%);
}
.hero_float4{
    position: absolute;
    right: 3%;
    /* width: 10%; */
    top: 45%;
    width: calc(80px + 5%);
}
.hero_float5{
    position: absolute;
    left: 15%;
    /* width: 10%; */
    bottom: 6%;
    width: calc(80px + 5%);
}
.hero_float6{
    position: absolute;
    right: 14%;
    /* width: 12%; */
    width: calc(80px + 7%);
    bottom: 8%;
}
@media only screen and (min-width: 10px) and (max-width: 500px){
    .why_choosebench:hover img{
        transform: scale(1) !important;
    }
    .whitewhy_body:last-child{
        margin-top: 20px;
    }
    .hero_section{
        min-height: 90vh;
    }
    .hero_section h1{
        margin-bottom: 30px;
        font-size: x-large;
    }
    .hero_section h3{
        margin-bottom: 10px;
        font-size: medium;
    }
    .hero_section span{
        margin-bottom:0px;
        font-size: small;
        width: 100%;
    }
    .hero_section button{
        font-size: small;
        padding: 0px 15px;
        margin-top: 15px;
    }
    .hero_float1{
        position: absolute;
        left: 3%;
        width: 15vh;
        top: 6%;
    }
    .hero_float2{
        position: absolute;
        right: 3%;
        width: 15vh;
        top: 2%;
    }
    .hero_float5{
        position: absolute;
        left: 2%;
        width: 15vh;
        bottom: 12%;
    }
    .hero_float6{
        position: absolute;
        right: 4%;
        width: 15vh;
        bottom: 10%;
    }
    .why_choosebench h1,.key_featuremain h1,.trackworkmain h1,.sucess_story h1{
        color: #C32A7C;
        margin-bottom: 5vh;
        font-size: x-large !important;
        font-weight: 600;
        text-align: center;
    }
    .why_choosebench img{
        margin-bottom: 20px;
    }
    .why_choosebench h5,.card_keyfeature h5{
        font-size: large;
    }
    .why_choosebench small,.card_keyfeature h5{
        font-size: small;
    }
    .arow_traccontent h5{
        font-size: large;
    }
    .arow_traccontent small{
        font-size: small;
    }
    .content_trackprocess img{
        width: 90% !important;
    }
    .arow_traccontent{
        padding: 10px !important;
    }
}
@media only screen and (min-width: 500px) and (max-width: 1100px){
    .hero_float1{
        position: absolute;
        left: 7.5%;
        width: 12%;
        top: 5%;
    }
    .hero_float2{
        position: absolute;
        right: 8%;
        width: 11%;
        top: 5%;
    }
    .hero_float5{
        position: absolute;
        left: 8%;
        width: 11%;
        bottom: 0%;
    }
    .hero_float6{
        position: absolute;
        right: 13%;
        width: 12%;
        bottom: -3%;
    }
}

/* Why choose us */
.why_choosebench{
    background-color: #FFF6EA;
    padding: 5vh 0px;
}
.why_choosebench h1{
    color: #C32A7C;
    margin-bottom: 5vh;
    font-weight: 600;
    text-align: center;
}
.why_choosebench img{
    width: 100%;
    overflow: hidden;
    transition: 0.4s;
}
.imghover_hiders{
    overflow: hidden;
    border-radius: 30px;
    height: max-content !important;
}
.why_choosebench:hover img{
    transform: scale(1.05);
}
.whitewhy_body:hover{
    box-shadow: 0px 2px 16px 0px #C32A7C;
}
.whitewhy_body{
    background-color: #fff;
    box-shadow: 0px 2px 16px 0px #0000001F;
    border-radius: 18px;
    padding: 20px;
    height: max-content;
    margin-bottom: 20px;
    transition: 0.2s;
}
.whitewhy_body:last-child{
    margin-bottom: 0px;
}
.whitewhy_body h5{
    color: #000;
    font-weight: 600;
}
.whitewhy_body small{
    color: #7F7F7F;
    font-weight: 400;
}

/* Key Features */
.key_featuremain{
    background-color: #fff;
    padding: 5vh 0px;
}
.key_featuremain h1{
    color: #C32A7C;
    margin-bottom: 5vh;
    font-weight: 600;
    text-align: center;
}
.button_keyfeatures{
    padding: 3px;
    border: 1px solid #C32A7C;
    border-radius: 100px;
}
.button_keyfeatures button:hover{
    background: #EE6BB1;
    color: #fff;
}
.button_keyfeatures .activekeyfeture{
    background-color: #C32A7C;
    color: #fff;
}
.button_keyfeatures button{
    padding: 7px 20px;
    border: none;
    border-radius: 100px;
    background-color: transparent;
    color: #000;
    font-weight: 600;
    transition: 0.4s;
}
.card_keyfeature:hover{
    box-shadow: 0px 2px 16px 0px #C32A7C;
}
.card_keyfeature:hover img{
    transform: scale(1.25);
}
.card_keyfeature{
    background: #FFFFFF;
    padding: 20px;
    box-shadow: 0px 2px 12px 0px #00000040;
    border-radius: 20px;
    transition: 0.4s;
}
.card_keyfeature h5{
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
}
.card_keyfeature small{
    color: #7F7F7F;
    font-weight: 400;
}
.card_keyfeature img{
    /* width: 15%; */
    width: calc(40px + 1%);
    margin-bottom: 15px;
    transition: 0.4s;
}

/* Trackwork */
.trackworkmain{
    background-color: #FFF6EA;
    padding: 5vh 0px;
}
.trackworkmain h1{
    color: #C32A7C;
    margin-bottom: 5vh;
    font-weight: 600;
    text-align: center;
}
.arow_traccontent{
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 12px 0px #00000040;
}
.arow_traccontent h5{
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
}
.arow_traccontent small{
    color: #7F7F7F;
    font-weight: 400;
}
.content_trackprocess img{
    width: 60%;
}
.content_trackprocess{
    margin-bottom: 20px;
}
.content_trackprocess img{
    width: 60%;
}
.dash_linetrack{
    border: 1px dashed #868686;
    width: 1px;
}
.mintrack_height{
    height: 75vh;
}
.maintrackminset{
    min-height: 100vh;
}
/* Success Story */
.sucess_story{
    background: linear-gradient(8deg, #C32A7C 50.79%, #FFFFFF 10.85%);
    padding: 5vh 0px;
}
.sucess_story h1{
    color: #C32A7C;
    margin-bottom: 5vh;
    font-weight: 600;
    text-align: center;
}
/* report contact us */
.contact_usreport{
    background: #1A5298;
}
.contact_usreport h1{
    color: #C32A7C;
    margin-top: 5vh;
    font-weight: 600;
    text-align: center;
    width: 100%;
}
.contact_usreport h5{
    color: #9C9C9C;
    margin-bottom: 5vh;
    font-weight: 500;
    text-align: center;
    width: 100%;
}
.reporthomemain{
    background-color: #fff;
    border-radius: 30px;
}
.reporthomemain .drag_uploaders{
    border-style: dashed;
    border-radius: 10px !important;
}
