.footer_logocontains img{
    width: 145px;
    margin-bottom: 30px;
    cursor: pointer;
}
.footer_logocontains h4{
    font-weight: 600;
    margin-bottom: 20px;
}
.footer_logocontains span{
    color: #7F7F7F;
    margin-bottom: 20px;
    text-align: center;
}
.subscribe_btn button{
    width: max-content !important;
    height: 52px;
    padding: 0px 50px ;
    background-color: #C32A7C;
    line-height: 0px;
    text-transform: capitalize;
    color: #fff;
    border-radius: 100px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif !important;
    border: none;
}
.terms_spanfooter span{
    color: #7F7F7F;
    margin-top: 20px;
    font-weight: 400;
}
.terms_spanfooter span a{
    color: #000;
    font-weight: 600;
}
.footer_socials a{
    padding: 0px 20px;
    margin: 10px 0px;
}
.footer_socials a img{
    width: 35px;
}
.footer_socials span{
    color: #7F7F7F;
    margin: 10px 0px;
}

@media only screen and (min-width: 10px) and (max-width: 500px){
    .subscribe_btn button{
        width: max-content !important;
        height: 35px;
        padding: 0px 20px ;
        background-color: #C32A7C;
        line-height: 0px;
        text-transform: capitalize;
        color: #fff;
        border-radius: 100px;
        font-weight: 500;
        font-family: "Montserrat", sans-serif !important;
        border: none;
    }
}