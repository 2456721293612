.editgrouphead h2{
    margin: 10px 0px !important;
}
.editgrouphead{
    border-bottom: 1px solid #BCBCBC !important;
}
.input_groupname input{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #9C9C9C;
    padding: 0px 10px;
    outline-color: #C32A7C !important;
}
.input_groupname select{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #9C9C9C;
    padding: 0px 10px;
}
.input_groupname span{
    font-size: small;
    margin-bottom: 5px;
    font-weight: 500;
}
.white_editgroup{
    background-color: #fff;
    box-shadow: 0px 0px 8.8px 0px #00000040;
    border-radius: 25px;
    padding: 30px 0px;
    margin:50px 0px ;
}
.white_editgroup h6{
    color: #8E8E8E;
}
.white_editgroup img{
    width: 25px;
    margin-right: 10px;
}
.white_editgroup .page_span{
    color: #000000;
    font-weight: 500;
    min-height: 35.4px;
    display: flex;
    align-items: center;
}
.white_editgroup small{
    margin-top: 20px;
    display: flex;
    align-items: center;
    color: #8E8E8E;
    font-weight: 500;
}
.white_editgroup small input{
    width: 18px;
    height: 18px;
    margin-right: 10px;
}
.white_editgroup small input[type=checkbox]:checked {
    accent-color: #C42B7D !important;
  }




/* Tabs */

@media only screen and (min-width: 10px) and (max-width: 500px){
    .downloadformbtn{
        margin-top: 10px !important;
    }
}
.cancel_btnbonly{
    border-radius: 100px;
    width: 120px;
    height: 35px;
    color: #C42B7D;
    border: 1px solid #C42B7D;
    background-color: #fff;
    font-weight: 500;
}
.save_btnbonly{
    border-radius: 100px;
    width: 120px;
    height: 35px;
    color: #fff;
    border: 1px solid #C42B7D;
    background-color: #C42B7D;
    font-weight: 500;
    padding: 0px !important;
}
.save_btnbonly img{
    width: 35px !important;
}
.tab_membersets{
    background-color: #fff;
    box-shadow: 0px 0px 8.8px 0px #00000040;
    border-radius: 25px;
    padding: 30px 0px;
    margin:50px 0px ;
}
.tab_membersets small{
    width: 90%;
    font-weight: 600;
    font-size: larger;
    margin-bottom: 30px;
}


.gridtop_editbtn{
    border-bottom: 1px solid #D4D4D4;
    padding:10px 0px;
}
.gridtop_editbtn:last-child{
    border-bottom: none !important;
}
.tab_membersets img{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 100px;
}
.tab_membersets span{
    color: #000;
    font-weight: 600;
}
.tab_membersets button{
    color: #C32A7C;
    background-color: #FFDAE8;
    border-radius: 100px;
    font-size: small;
    width: 80px;
    height: 30px;
    border: 1px solid #FFDAE8;
    font-weight: 600;
}
.list_viewoverflow{
    overflow: auto;
}
.main_listviewgroup{
    background-color: #fff;
    box-shadow: 0px 0px 7.11px 0px #0000001F;
    border-radius: 15px;
    padding: 10px 20px;
    margin-top:20px;
    min-width: 1000px !important;
}
.main_listviewgroup span{
    color: #8E8E8E;
    margin-bottom: 10px;
}
.main_listviewgroup .groupnames{
    color: #000000;
    font-weight: 600;
    font-size: medium;
    height: 40px;
    display: flex;
    align-items: center;
}
.main_listviewgroup .activestatus{
    color: #51CC64;
    font-weight: 600;
    font-size: medium;
    height: 40px;
    display: flex;
    align-items: center;
    align-content: center;
    gap: 10px;
}
.main_listviewgroup .activestatus span{
    color: #fff !important;
    margin: 0px !important;
}
.memberoverflower{
    overflow: hidden;
}
.main_listviewgroup img{
    width: 40px;
    margin-right: 10px;
    height: 40px;
    border-radius: 100px;
}
.main_listviewgroup .action_btnstable{
    background: #FFDAE8;
    color: #C42B7D;
    border-radius: 100px;
    border: 1px solid #FFDAE8;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.pagination_btn .arrowpaginate:hover,.pagination_datagrid .nexter_btns:hover{
    background-color: #1A5298 !important;
    color: #fff;
}
.pagination_btn button:hover,.pagination_datagrid button:hover{
    opacity: 0.8;
}
.pagination_btn button:hover,.pagination_datagrid .page-btn:hover{
    background-color: #C32A7C !important;
    color: #fff;
    font-weight: 600;
}
.pagination_btn button{
    background-color: transparent;
    height: 35px;
    width: 35px;
    margin: 0px 5px;
    border: 1px solid #DFE3E8;
    border-radius: 4px;
    color: #000;
    font-weight: 600;
    padding: 0px !important;
}
.pagination_btn .arrowpaginate{
    color: #C4CDD5 !important;
}
.pagination_btn button.active {
    background-color: #C32A7C; /* Active color */
    color: white;
    border-color: #C32A7C;
  }
.pagination_btn button:disabled {
    cursor: not-allowed;
    background: #919EAB;
}
.tooltip_divs{
    border-radius: 10px;
    border: 2px solid #1A5298;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-left:20px ;
}
.tooltip_divs .tooltip_icons{
    color: #1A5298;
    padding: 3px 8px;
    border-radius: 100px;
    border: 2px solid #1A5298;
    font-size: small;
}
  
.group_tooltips .tooltip-inner {
    background-color: #E8F2FF !important; /* Change to your desired color */
    color: #000; /* Optional: Change text color */
  }
  
.group_tooltips .tooltip-arrow::before {
    border-right-color: #E8F2FF !important;
}
.checkbox_smallcolor{
    color:#000 !important;
    font-weight: 500 !important;
}
.border_checkbox{
    padding: 15px 20px !important;
    border: 2px solid #A3A3A3;
    border-radius: 10px;
    margin-top: 20px;
}
.link_mailmember{
    color: #1A5298;
    font-weight: 600;
    text-decoration: none;
    transition: 0.2s;
}
.link_mailmember:hover{
    text-decoration: underline;
    opacity: 0.8;
}