.admintab_btnredirect{
    background-color: transparent !important;
    border-radius: 100px;
    border: 1px solid #C3C3C3;
    box-shadow: 3px 3px 4px 0px #00000040 inset;
}
.admintab_btnredirect button{
    border: none;
    width: max-content !important;
    color: #000;
    border-radius: 100px;
    padding: 0px 15px;
    margin: 0px !important;
}
.admintab_btnredirect .active_tabbtn{
    background-color: #C42B7D;
    color: #fff;
}
.knowledgetab_btnredirect{
    background-color: transparent !important;
    border-radius: 100px;
    border: 1px solid #C3C3C3;
    box-shadow: 3px 3px 4px 0px #00000040 inset;
}
.knowledgetab_btnredirect button{
    border: none;
    width: max-content !important;
    color: #000;
    border-radius: 100px;
    padding: 0px 25px;
    margin: 0px !important;
}
.knowledgetab_btnredirect .active_tabbtn{
    background-color: #1A5298;
    color: #fff;
}
@media only screen and (min-width: 10px) and (max-width: 500px){
    .admintab_btnredirect button{
        font-size: small;
        height: 35px;
    }
    .admintab_btnredirect{
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
    }
    .knowledgetab_btnredirect button{
        font-size: small;
        height: 35px;
    }
    .knowledgetab_btnredirect{
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border-radius: 20px;
    }
    .notification_conatiner{
        padding: 10px 10px !important;
    }
    .innernoti_content img{
        width: 30px !important;
    }
}

.border_catsvalidate{
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #C6C6C6;
}
/* Notifications */
.notification_conatiner{
    background: #fff;
    border: 1px solid #D8D8D8;
    padding: 20px 20px;
}
.notifications_btn button svg{
    fill: #1A5298;
    transition: 0.4s;
}
.notifications_btn button:hover svg{
    fill: #fff;
}
.notifications_btn button:hover{
    color: #fff;
    background-color: #1A5298;
}
.notifications_btn button{
    color: #1A5298;
    background-color: transparent;
    border: 1px solid #1A5298;
    border-radius: 5px;
    width: max-content;
    padding: 3px 30px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
}
.innernoti_content{
    border: 1px solid #CECECE;
    position: relative;
    padding: 10px ;
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
}
.bg_unread_dotalso{
    background-color: #EEF5FF;
}
.bg_unread_dotalso::before{
    content: "";
    position: absolute;
    border-radius: 100px;
    width: 10px;
    height: 10px;
    background-color: #C42B7D;
    top: 5px;
    right: 5px;
}
.innernoti_content span{
    color: #626262;
    font-weight: 400;
}
.cursorpointgrid_notify .innernoti_content:hover span b{
    text-decoration: underline;
    color: #1A5298;
}
.innernoti_content span b{
    color: #000;
    font-weight: 600;
    margin-right: 8px;
    transition: 0.2s;
}
.innernoti_content img{
    width: 50px;
}
.innernoti_content::after{
    width: 5px;
    position: absolute;
    background-color: #1A5298;
    content: "";
    height: 50px;
    left: 0px;
    border-radius: 0px 100px 100px 0px;
}
.innernoti_content small{
    color: #1A5298;
    font-weight: 500;
    margin-top: 8px;
}
.noti_contentgrid button{
    color: #989898;
    border: none;
    background-color: transparent;
    padding: 0px;
    height: max-content;
    width: max-content;
    font-size: xx-large;
}
