



/* Job seeker */
.main_jobseeker{
    background: #fff;
    border: 1px solid #D8D8D8;
    padding: 20px 20px !important;
}
.jobseekerhead h3{
    color: #C42B7D;
    font-weight:600 ;
    display: flex;
    align-items: center;
}
.jobseekerhead h3 select{
    color: #595959;
    font-size: medium;
    margin-left: 10px;
    padding: 3px 10px;
    border: 1px solid #C32A7C;
    border-radius: 5px;
    font-weight: 600;
}
.jobseekerhead span{
    color: #9C9C9C;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    font-weight: 500;
}
.jobseekerhead span b{
    color: #000;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s;
}
.jobseekerhead span b:hover{
    color: #1A5298;
    text-decoration: underline;
}
.jobseekersearch{
    background: #EFEFEF;
    border: 1px solid #D9D9D9;
    align-items: center;
    border-radius: 100px;
}
.jobseekersearch .iconcol{
    color: #9C9C9C;
    width: 100%;
    text-align: center;
}
.jobseekersearch input{
    width: 100%;
    height: 40px;
    background-color: transparent !important;
    box-shadow: none !important;
    border: none;
    outline: none;
}
.jobseekersearch button{
    background-color: #C42B7D;
    color: #fff;
    font-weight: 600;
    width: 100%;
    border-radius: 100px;
    height: 40px;
    border: none;
}
.statusselectbtn button{
    width: 40px;
    height: 40px;
    padding: 0px 0px;
    border-radius: 10px;
    border: 1px solid #C42B7D;
    color:#C42B7D;
    font-weight: 600;
    background-color: transparent;
    margin: 0px 5px;
}
.statusselectbtn select{
    width: max-content;
    height: 40px;
    width: max-content !important;
    padding: 0px 15px;
    border-radius: 100px;
    border: 1px solid #C42B7D;
    color:#C42B7D;
    font-weight: 600;
    outline-color: #C32A7C;
    margin-left: 5px;
}
.downloadformbtn{
    height: 40px;
    width: 150px !important;
    border-radius: 100px !important;
    border: 1px solid #C42B7D;
    color:#fff !important;
    font-weight: 600;
    background-color: #C42B7D !important;
    margin: 0px 10px !important;
}
.downloadformbtn .icons{
    margin-left: 10px;
}
.downloadformbtn .iconrotate{
    margin-left: 10px;
    transform: rotate(45deg);
}
.jobseekgridmain{
    background: #FFFFFF;
    box-shadow: 0px 0px 7.11px 0px #0000001F;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.gridtop_menuer img{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 100px;
}
.gridtop_menuer span{
    color: #000;
    font-weight: 600;
}
.gridtop_menuer .dropdown-toggle::after{
    display: none !important;
}
.gridtop_menuer .dropdown-toggle{
    background-color: transparent;
    border: none;
    color: #8E8E8E;
    padding: 0px;
}
.progressspangrid{
    background-color: #B8D7FF;
    margin: 15px 0px;
    padding: 5px 20px;
    border-radius: 100px;
    color: #1A5298;
    font-weight: 600;
}
.bg_dynaspangroup{
    margin: 5px 3px;
    padding: 5px 20px;
    border-radius: 100px;
    font-weight: 600;
}
.bg_dynaspangroupjsa{
    padding: 5px 20px;
    border-radius: 100px;
    font-weight: 600;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.member_grid h5{
    width: 100%;
    font-weight:600 ;
}
.member_grid img{
    width: 30px;
    height: 30px;
    margin-right: 5px;
}
.linkgridview{
    background-color: #fff;
    padding: 7px 10px;
    margin: 10px 0px;
    box-shadow: 0px 0.89px 5.33px 0px #0000001F;
    border-radius: 10px;
}
.linkgridview span{
    font-size: small;
    color: #8E8E8E;
    margin: 3px 0px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.linkgridview span a{
    font-size: small;
    color: #1A5298;
    font-weight: 500;
    margin-left: 10px;
}
.gridviewendtext span{
    color: #8E8E8E;
    font-weight: 600;
}
.gridviewendtext font{
    color: #000;
    font-weight: 500;
}
.suspent_btn .icons{
    margin-left: 10px;
}
.suspent_btn{
    width: 150px;
    color: #fff;
    padding: 0px 10px;
    background-color: #C42B7D;
    border: 1px solid #C42B7D;
    border-radius: 100px;
    height: 35px;
    font-weight:600 ;
    display: flex;
    justify-content: center;
    align-items: center;
}
.suspent_btn img{
    width: 35px !important;
    height: 35px !important;
    margin: 0px !important;
}
.main_listscollers{
    overflow: auto;
}
.gray_bglistview{
    margin-top: 20px;
    background-color: #EFEFEF;
    padding: 15px 10px;
    border-radius: 10px 10px 0px 0px;
    min-width: 1200px !important;
}
.gray_bglistview h5{
    margin: 0px;
    font-weight: 600;
    width: 100%;
}
.jobseeklistmain{
    background: #FFFFFF;
    box-shadow: 0px 0px 7.11px 0px #0000001F;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    min-width: 1200px !important;
}
.linklistview span{
    font-size: small;
    color: #8E8E8E;
    margin: 3px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.linklistview span a{
    font-size: small;
    color: #1A5298;
    font-weight: 500;
    margin-left: 10px;
}
.jobseeklistmain .datelist{
    color: #000;
    font-weight: 500;
}
.jobseeklistmain .suspent_btn{
    width: 170px !important;
    background-color: #fff;
    padding: 0px 20px;
    color: #C42B7D;
    border: 1px solid #C42B7D;
    border-radius: 100px;
    height: 40px;
    font-weight:600 ;
}

/* jobdetails */
.grid_jobseekertsker{
    background-color: #fff;
    border: 1px solid #C32A7C;
    box-shadow: 0px 0px 7px 0px #0000001F;
    padding: 15px;
    border-radius: 20px;
}
.jobdegrid1img{
    width: 30%;
    margin-bottom: 20px;
}
.jobdegrid1span{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    align-items: center;
    margin: 5px 0px;
}
.jobdegrid1span small{
    color: #8E8E8E;
    font-weight: 500;
}
.jobdegrid1span text{
    color: #1A5298;
    font-weight: 600;
    font-size: larger;
}
.jobdegrid2img{
    width: 25%;
    margin-bottom: 20px;
}
.jobdegrid2h6{
    color: #000;
    font-weight: 600;
    margin-bottom: 20px;
}
.jobdegrid1span{
    display: flex;
    align-items: center;
    width: 100%;
    align-items: center;
    margin: 5px 0px;
}
.jobdegrid2span small{
    color: #8E8E8E;
    font-weight: 500;
}
.jobdegrid2span text{
    color: #1A5298;
    font-weight: 600;
    font-size: larger;
}
.progreesbar_grid{
    width: 100%;
    height: 10px;
    color: #C32A7C !important;
    margin-top: 10px;
    border-radius: 100px;
}
.progreesbar_grid .progress-bar{
    background-color: #C32A7C !important;
    border-radius: 100px;
}
.gradianjobgrides{
    padding: 20px;
    background: linear-gradient(270deg, #C42B7D 0%, #1A5298 100%);
    border-radius: 20px;
}
.gradianjobgrides small{
    color: #fff;
    margin-bottom: 10px;
    font-weight: 600;
}
.gradianjobgrides h5{
    color: #fff;
    margin-bottom: 10px;
    font-weight: 600;
}
.gradianjobgrides button{
    width: max-content;
    height: 40px;
    width: max-content !important;
    padding: 0px 25px;
    border-radius: 100px;
    border: 1px solid #C42B7D;
    color:#C42B7D;
    font-weight: 600;
    background-color: #fff;
    margin: 20px 0px;
}
.gradianjobgrides button .iconrotate{
    margin-left: 10px;
    transform: rotate(45deg);
}
.endgrid_list{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 12px 0px #0000002E;
}
.endgrid_list h5{
    padding: 10px 20px;
    margin: 0px;
    font-weight: 600;
    position: relative;
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
}
.endgrid_list h5 small{
    color: #8E8E8E;
    font-size: x-small;
    font-weight: 500;
    margin-top: 5px;
}
.endgrid_list h5::after{
    content: "";
    background-color: #C32A7C;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 6px;
    border-radius: 100px;
}
.endgrid_border{
    border-bottom: 1px solid #E3E3E3;
    margin-bottom: 20px;
}
.endgrid_bordercontent{
    border-bottom: 1px solid #E3E3E3;
    margin-bottom: 10px;
    padding-bottom: 10px;
}
.endgrid_bordercontent img{
    width: 10%;
}
.endgrid_bordercontent span{
    width: 85%;
    display: flex;
    flex-direction: column;
}
.endgrid_bordercontent span text{
    font-size: medium;
    color: #000;
    font-weight: 600;
}
.endgrid_bordercontent span small{
    font-size: small;
    color: #8E8E8E;
    font-weight: 500;
}
.jobseekers_profilealls h4{
    color: #C42B7D;
    margin: 20px 0px;
    font-weight: 600;
}
.jobseekers_profilealls span{
    color: #828282;
    margin-bottom: 10px;
    font-weight: 500;
}
.jobseekers_profilealls p{
    color: #000;
    margin-bottom: 16px;
    font-weight: 500;
}
.jobseekers_profilealls .seekprof_borders{
    border-bottom: 1px solid #CBCBCB;
    margin-bottom: 16px;
}
.jobseekers_profilealls a{
    margin-bottom: 16px;
    font-weight: 500;
    width: max-content !important;
}
.jobssekprofileclick span,.cursetespoint{
    cursor: pointer;
}
.capssets{
    text-transform: capitalize;
}
.back_buttonminus{
    background-color: transparent;
    border-radius: 100px;
    color:#C32A7C;
    font-weight: 600;
    border: 1px solid #C32A7C;
    width: 100px;
    height: 40px;
}
.ofcanvas_widthsetters{
    width: 60vw !important;
}
.MuiDataGrid-root{
    font-family: "Montserrat", sans-serif !important;
    font-size: medium !important;
    font-weight: 500 !important;
}
.datatable_jobpagi{
    height: 100% !important;
}
.datatable_jobpagi .MuiDataGrid-footerContainer {
    display: none;
}

.datatable_jobpagi .MuiOutlinedInput-input{
    padding: 10px !important;
}
.datatable_jobpagi .value_inputfilter{
    padding: 8px !important;
    border-radius: 4px;
    border: 1px solid #8282828b;
    background-color: transparent;
    margin-right: 5px;
}
.datatable_jobpagi select{
    padding: 8px !important;
    border-radius: 4px;
    border: 1px solid #8282828b;
    background-color: transparent;
    margin-right: 5px;
}
.datatable_jobpagi .filterremovebtn{
    background-color: transparent;
    padding: 8px 20px;
    border: 1px solid #C32A7C;
    color: #C32A7C;
    margin-left: 10px;
    border-radius: 100px;
    font-weight: 600;
}
.datatable_jobpagi .filteraddbtn{
    background-color: #C42B7D;
    padding: 8px 20px;
    border: 1px solid #C42B7D;
    color: #fff;
    border-radius: 100px;
    font-weight: 600;
}
.datatable_jobpagi .show_filterbtn{
    background-color: #1A5298;
    padding: 8px 15px;
    border: 1px solid #1A5298;
    color: #fff;
    border-radius: 100px;
    font-weight: 600;
    margin-bottom: 20px;
}
.datatable_jobpagi .filterrages label{
    top: -10%;
}

.datatable_jobpagi .pagination_datagrid button{
    border: 1px solid #DFE3E8;
    border-radius: 4px;
    background-color: transparent !important;
    padding: 0px;
    height: 30px;
    width: 30px;
    margin: 0px 5px;
}
.datatable_jobpagi .pagination_datagrid .active{
    color: #fff !important;
    background-color: #C32A7C !important;
}
.datatable_jobpagi .pagination_datagrid select{
    border: none ;
    outline-color: #C32A7C;
}
.datatable_jobpagi_indi select{
    padding: 8px !important;
    border-radius: 4px;
    border: 1px solid #8282828b !important;
    background-color: transparent;
    margin: 0px 5px;
}
.datatable_jobpagi_indi .filterremovebtn{
    background-color: transparent;
    padding: 8px 20px;
    border: 1px solid #C32A7C;
    color: #C32A7C;
    margin-left: 10px;
    border-radius: 100px;
    font-weight: 600;
}
.datatable_jobpagi_indi .filteraddbtn{
    background-color: #C42B7D;
    padding: 8px 20px;
    border: 1px solid #C42B7D;
    color: #fff;
    border-radius: 100px;
    font-weight: 600;
}
.datatable_jobpagi_indi .show_filterbtn{
    background-color: #1A5298;
    padding: 8px 15px;
    border: 1px solid #1A5298;
    color: #fff;
    border-radius: 100px;
    font-weight: 600;
    margin-bottom: 20px;
}
.datatable_jobpagi_indi .filterrages label{
    top: -10%;
}

.datatable_jobpagi_indi .pagination_datagrid button{
    border: 1px solid #DFE3E8;
    border-radius: 4px;
    background-color: transparent !important;
    padding: 0px;
    height: 30px;
    width: 30px;
    margin: 0px 5px;
}
.datatable_jobpagi_indi .pagination_datagrid .active{
    color: #fff !important;
    background-color: #C32A7C !important;
}
.datatable_jobpagi_indi .pagination_datagrid select{
    border: none ;
    outline-color: #C32A7C;
}
.download_csvbtndatatable{
    border: 1px solid #1A5298;
    border-radius: 100px;
    font-weight: 600;
    margin-bottom: 20px;
    padding: 8px 15px;
    background-color: #1A5298 !important;
    color: #fff !important;
    width: 200px !important;
}