.uppercasers{
    text-transform: capitalize;
}
.border_botomer{
    border-bottom: 1px solid #BCBCBC;
    padding-bottom: 20px;
}
.note_bgyesno{
    background-color: #FFF5BF;
    border-radius: 10px;
    padding: 10px 15px;
}
.note_bgyesno b{
    color: #575757;
    font-weight: 600;
}
.note_bgyesno span small{
    color: #797979;
    font-weight: 500;
    font-size: medium;
    padding-left: 5px;
}
.note_bgyesno span small:first-child{
    margin-bottom: 10px !important;
}
.i94validateinput input,.i94validateinput select,.i94validateinput .fileUploadInput{
    height: 50px !important;
}
.i94validateinput .fileUploadInput label{
    height: 50px !important;
}
.i94validateinput .fileUploadInput input{
    border: none !important;
}
.i94validateinput .main_radioregister input{
    width: 15px !important;
    height: 15px !important;
    margin: 10px;
}
.custom_fileshows .fileUploadInput{
    background-color: #fff !important;
    height: 50px !important;
}
.custom_fileshows .fileUploadInput label{
    height: 50px !important;
}
.custom_fileshows .fileUploadInput input{
    border: none !important;
}
.radios_customs .main_radioregister input{
    width: 15px !important;
    height: 15px !important;
    margin: 10px;
}
.i94validateinput span{
    color: #000;
    font-weight: 500;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 30px;
}
.radios_customs span small{
    color:red;
    margin-left: 5px;
    font-size: medium;
}
.i94validateinput span small{
    color:red;
    margin-left: 5px;
    font-size: medium;
}
.i94viewvalidate p{
    color: #000;
    font-weight: 400;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 10px;
}
.i94viewvalidate span{
    color: #828282;
    font-weight: 500;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 30px;
}
.i94viewvalidate span small{
    color:red;
    margin-left: 5px;
    font-size: medium;
}
.i94validateinput input{
    color: #000;
    font-weight: 500;
    width: 100%;
    border: 1px solid #9C9C9C;
    height: 50px;
    border-radius: 5px;
    margin-bottom: 5px;
    outline-color: #C32A7C;
    padding: 0px 10px;
}
.i94validateinput textarea{
    color: #000;
    font-weight: 500;
    width: 100%;
    border: 1px solid #9C9C9C;
    /* height: 100px; */
    resize: none;
    border-radius: 5px;
    margin-bottom: 5px;
    outline-color: #C32A7C;
    padding: 0px 10px;
}
.i94validateinput p{
    text-transform: capitalize;
}

.i94validateinput .date_pickermux .MuiOutlinedInput-notchedOutline{
    border: 1px solid #9C9C9C !important;
}
.i94validateinput .date_pickermux .css-lxfshk-MuiInputBase-root-MuiOutlinedInput-root{
    height: 50px !important;
    border-radius: 5px;
    box-shadow:  none !important;
    outline: none !important;
    margin-bottom: 5px;
    background-color: #fff;
}
.i94validateinput .select_withsearch .select__control{
    border: 1px solid #9C9C9C;
    padding: 0px 10px;
}
.i94validateinput .select_withsearch .select__input-container{
    height: 48px;
    margin: 0px;
    padding: 0px;
}
.i94validateinput .select_withsearch .css-hlgwow{
    padding: 0px;
}
.for_textarespec textarea{
    height: 250px;
}
.custom_comonlabel{
    font-weight: 500;
    margin-bottom: 10px;
}
.sideh4searcheq{
    margin-bottom: 0px;
    font-weight: 500;
}


.tast_knowledeaccord .accordion-body{
    padding-top: 0px;
    color: #444444;
    font-weight: 500;
}
.tast_knowledeaccord .accordion{
    --bs-accordion-btn-bg:transparent;
    --bs-accordion-border-radius: 0px !important;
}
.tast_knowledeaccord .accordion-body span{
    cursor: pointer;
    margin: 3px 0px;
    width: 90%;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tast_knowledeaccord .accordion-button{
    padding: 20px 10px !important;
    color: #C32A7C !important;
}
.tast_knowledeaccord .accordion-button::after{
    color: #C32A7C;
}
.tast_knowledeaccord .accordion-button img{
    width: 30px;
    height: 30px;
    transition: 0.4s;
}
.tast_knowledeaccord .accordion-button:not(.collapsed) img{
    transform: rotate(180deg);
}
.tast_knowledeaccord .accordion-item{
    border: none;
    border-bottom: 1px solid #BCBCBC !important;
    background-color: transparent;
}
.tast_knowledeaccord .accordion-button:not(.collapsed){
    background-color: transparent;
    color: #000;
    font-weight: 600;
    box-shadow: none;
}
.tast_knowledeaccord .accordion-button:focus{
    box-shadow: none !important;
}
.tabmin_shorthis{
    min-width: 900px !important;
    border-bottom: 1px solid #9C9C9C !important;
    margin-bottom: 20px;
}
.span_timeshowers span{
    margin-top: 10px;
}
.coustom_textarea textarea{
    height: 200px;
}
.select_boxcheckbox input{
    width: 20px !important;
    height: 20px !important;
    margin: 0px !important;
}
.select_boxcheckbox label{
    margin-left: 10px !important;
    height: 100%;
    display: flex;
    align-items: center;
}
.bold_valikhowledge a{
    font-weight: 500 !important;
}

/* Admission */
.admission_shows{
    box-shadow: 0px 0px 13.2px 0px #00000040;
    background-color: #fff;
    padding: 20px;
}
.admission_shows span{
    display: flex;
    color: #838383;
    width: 100%;
    justify-content: space-between;
    font-weight: 500;
    margin-top: 15px;
}
.admission_shows span b{
    color: #000;
    font-weight: 700;
}
.totalspan_admissionshow{
    padding-top: 15px;
    border-top: 1px solid #828282;
    display: flex;
    font-weight: 700 !important;
    color: #000 !important;
    width: 100%;
    justify-content: space-between;
}
.totalspan_admissionshow b{
    font-weight: 700 !important;
    color: #C32A7C !important;
}
.fessthanks .main_tickiconpopup{
    color: #1D981A;
    background-color: #fff;
    border: 3px solid #1D981A;
}
.fessthanks h3{
    width: 100%;
    text-align: center;
    margin: 20px 0px;
    font-weight: 600;
}
.fessthanks small{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: medium;
}
.fessthanks span{
    font-weight: 500;
    color: #838383;
    margin-top: 20px;
}
.fessthanks span b{
    font-weight: 700;
    color: #C32A7C;
    margin-left: 10px;
}
.thaks_topline{
    border-top: 1px solid #D2CFCF;
}
.main_crossred{
    font-size: 90px;
    color: #D61D20;
    background-color: #fff;
    border-radius: 100px;
    padding: 3px;
    border: 3px solid #F1ADAE;
}
.dtagridhistory_pagi .MuiDataGrid-footerContainer{
    display: none;
}
.tab_controllers .tab-content{
    width: 100% !important;
}
.modal_statuscomplete .statusicons{
    font-size: 50px;
    margin-bottom: 20px;
    color: #1A5298;
}
.modal_statuscomplete .canbtn_pop{
    border-radius: 100px !important;
}
.seekerondetail{
    width: max-content;
    color: #000;
    font-weight: 700;
    text-decoration: none;
    transition: 0.2s;
}
.seekerondetail:hover{
    color: #1A5298;
    text-decoration: underline;
}
.checkinagreement{
    color: #C32A7C;
    margin: 0px 10px;
}
.for_textarespec p,.i94validateinput p,.radios_customs p{
    text-transform: none !important;
}
.small_headingstatuses{
    font-size: medium;
    margin-left: 10px;
    color: #797979;
}