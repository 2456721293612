.rolesgroupgridmain{
    background: #FFFFFF;
    box-shadow: 0px 0px 7.11px 0px #0000001F;
    border-radius: 10px;
    margin-bottom: 20px;
}
.gridtop_menuergroup{
    border-bottom: 1px solid #D4D4D4;
    padding: 15px ;
}
.gridtop_menuergroup h4{
    color: #000;
    font-weight: 600;
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rolesgroupgridmain .dropdown-toggle::after{
    display: none !important;
}
.rolesgroupgridmain .dropdown-toggle{
    background-color: transparent;
    border: none;
    color: #8E8E8E;
    padding: 0px 10px;
    border-radius: 100px;
}
.switchbtn_withtext{
    margin-top: 15px;
}
.switchbtn_withtext font{
    color: #8E8E8E;
    margin-left: 15px;
}
.switchbtn_withtext font b{
    color: #000;
    margin-left: 5px;
    font-weight: 500;
}
.gropadmingridcont{
    padding:0px 10px;
    height: 300px;
    overflow: auto;
}
/* width */
.gropadmingridcont::-webkit-scrollbar {
    width: 12px;
  }
  
  /* Track */
.gropadmingridcont::-webkit-scrollbar-track {
    background: #FAFAFA;

  }
   
  /* Handle */
.gropadmingridcont::-webkit-scrollbar-thumb {
    background: #C1C1C1;
    border: 3px solid #fff;
    box-sizing: border-box;
    cursor: pointer;
  }
  
  /* Handle on hover */
.gropadmingridcont::-webkit-scrollbar-thumb:hover {
    background: #C1C1C1;
  }
.gropadmingridcont small{
    font-weight: 500;
    font-size: larger;
    padding: 10px 0px;
    width: 100%;
    background-color: #FFFFFF;
    color: #000;
    z-index: 4;
    position: sticky;
    top: 0px;
}
.gridtop_editbtn{
    border-bottom: 1px solid #D4D4D4;
    padding:10px 0px;
}
.gridtop_editbtn:last-child{
    border-bottom: none !important;
}
.gropadmingridcont img{
    width: 45px;
    height: 45px;
    margin-right: 10px;
    border-radius: 100px;
}
.gropadmingridcont span{
    color: #000;
    font-weight: 600;
    font-size: large;
    width: calc(100% - 60px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.gropadmingridcont button{
    color: #C32A7C;
    background-color: #FFDAE8;
    border-radius: 100px;
    font-size: small;
    width: 100%;
    height: 30px;
    border: 1px solid #FFDAE8;
    font-weight: 600;
}
.box_shadedropdown{
    box-shadow: 0px -1px 5.6px 0px #00000059;
    border: none !important;
    border-radius: 15px;
}
.edit_dropdown:last-child{
    border: none !important;
}
.edit_dropdown{
    width: 100%;
    text-align: left;
    background-color: transparent;
    border-bottom: 1px solid #CFCFCF !important;
    border: none;
    padding: 8px ;
}
.edit_dropdown .icon_set{
    color: #C32A7C;
    margin-right: 10px;
}
.edit_dropdown:hover{
    background-color: #c32a7c16;
}