  
  .profile-upload-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .profile-upload-label {
    cursor: pointer;
    display: block;
    width: 70px;
    height: 60px;
    border-radius: 10px;
    background-color: #DADADA;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px solid #ccc;
  }
  
  .profile-upload-label img {
    width: 100%;
    height: 100%;
  }
  
  .placeholder-icon img {
    width: 40px;
    height: 40px;
  }
  
  .profile-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.profile_uploadlab small{
    color: #C42B7D;
    margin-left: 10px;
    font-weight: 500;
}

.add_memberfields span{
    color: #000000;
    margin-bottom: 3px;
    font-weight: 500;
}
.memberfield_individual input{
    background: #FFFFFF;
    border: 1px solid #9C9C9C;
    height: 40px;
    border-radius: 5px;
    padding: 0px 10px;
    outline-color: #C32A7C;
    width: 100%;
}
.memberfield_individual .date_pickermux .MuiOutlinedInput-root{
    background-color: #fff;
}
.modal_cancel{
  border: 1px solid #C42B7D;
    border-radius: 100px;
    height: 35px;
    width: max-content;
    padding: 0px 20px;
    background-color: transparent;
    color: #C42B7D;
    font-weight: 600;
    font-size: smaller;
}
.modal_delete{
  border: 1px solid #C42B7D;
    border-radius: 100px;
    height: 35px;
    width: max-content;
    padding: 0px 20px;
    background-color: #C42B7D;
    color: #fff;
    font-weight: 600;
    font-size: smaller;
}
.add_memberfields .input_witheye button{
    width: 20% !important;
    height: 40px !important;
    border: none !important;
    border-radius: 0px !important;
    background-color: transparent !important;
    color:#7F7F7F !important;
}
.add_memberfields .input_witheye{
    color: #000;
    font-weight: 500;
    width: 100% !important;
    border: 1px solid #9C9C9C;
    height: 40px;
    border-radius: 5px;
    margin-bottom: 5px;
    outline-color: #C32A7C;
    padding: 0px 0px;
    background-color: #fff;
}
.add_memberfields .input_witheye input{
    color: #000;
    font-weight: 500;
    width: 80% !important;
    border: none;
    background-color: transparent !important;
    height: 40px;
    border-radius: 5px;
    margin-bottom: 5px;
    outline-color: #C32A7C;
    padding: 0px 10px;
}
.border_btnpink{
    border: 1px solid #C42B7D;
    border-radius: 100px;
    height: 40px;
    width: 125px;
    background-color: transparent;
    color: #C42B7D;
    font-weight: 600;
    padding: 0px;
    font-size: smaller;
}
.addsubs_btn{
  width: 150px !important;
}
.bgpink_btnpink{
    border: 1px solid #C42B7D;
    border-radius: 100px;
    height: 40px;
    width: 125px;
    padding: 0px;
    background-color: #C42B7D;
    color: #fff;
    font-weight: 600;
    font-size: smaller;
}
.bgpink_btnpink img{
  width: 40px;
}
.border_btnpink_lowhegihnt{
    border: 1px solid #C42B7D;
    border-radius: 100px;
    height: 35px;
    width: 125px;
    background-color: transparent;
    color: #C42B7D;
    font-weight: 600;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: small;
}
.bgpink_btnpink_lowheight{
    border: 1px solid #C42B7D;
    border-radius: 100px;
    height: 35px;
    width: 125px;
    padding: 0px;
    background-color: #C42B7D;
    color: #fff;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: small;
}
.export_csvbtn{
  border-radius: 100px !important;
  background-color: #C42B7D !important;
  width: max-content !important;
  color: #fff !important;
  width: 150px !important;
}
.export_csvbtn img{
  width: 40px !important;
}
.search_perminewsec{
    width: 300px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #9C9C9C;
    padding: 0px 10px;
    outline: none !important;
}