/* KBA Compare */
.added_text {
    background-color: #d4fcdc; /* Light green */
  }
  
  .removed_text {
    background-color: #fcdcdc; /* Light red */
    text-decoration: line-through;
  }
  
  .changed_artical {
    background-color: #fce5cd; /* Light orange */
  }

.linkofhistoryattach{
    font-weight: 600;
    text-decoration: none;
}
.linkofhistoryattach:hover{
    text-decoration: underline;
}
.clicked_boldarticle{
    font-weight: 700;
    color: #1A5298;
}
.clicked_boldarticle button{
    font-weight: 700;
    color: #1A5298 !important;
}
.content_container_articals{
    width: 100%;
    padding: 110px 20px 20px 325px;
    background-color: #FFF6EA !important;
    min-height: 100vh;
}
.content_container_articals_drag {
    width: 100%;
    padding: 110px 20px 20px 20px;
    background-color: #FFF6EA !important;
    min-height: 100vh;
}
.hide_mobitr{
    display: block;
}
.main_category_btn{
    border-radius: 100px;
    background-color: #C32A7C;
    color: #fff;
    font-weight: 600;
    border: 1px solid #C32A7C;
    margin-top: 15px;
    width: max-content;
    padding: 7px 20px;
}
.closecat_btn{
    border-radius: 100px;
    background-color: #C32A7C;
    color: #fff;
    font-weight: 600;
    border: 1px solid #C32A7C;
    margin-top: 15px;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    padding: 0px 0px;
}
/* Reply */
.reply_comment_btn button{
    background-color: #C42B7D;
    border-radius: 100px;
    width: 150px;
    color: #fff;
    font-weight: 600;
    margin-top: 10px;
    border: 1px solid #C42B7D;
    height: 35px;
}
.reply_comment_btn .reply_btntext{
    background-color: transparent;
    border-radius: 100px;
    width: max-content;
    color: #1A5298;
    font-weight: 600;
    margin-top: 10px;
    border: none;
    height: 35px;
}
.reply_comment_btn textarea{
    width: 100% !important;
    resize: none;
    height: 100px;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    outline-color: #C32A7C;
}
@media only screen and (min-width: 10px) and (max-width: 500px){
    .content_container_articals, .content_container_articals_drag{
        width: 100%;
        padding: 70px 10px 20px 10px;
    }
    .klgcategory_main{
        padding-left: 30px !important;
        padding-top: 70px !important;
    }
    .validate_dragers{
        padding-left: 30px !important;
        padding-top: 70px !important;
    }
    .dragging_cats{
        position: fixed !important;
        width: 300px !important;
        height: 100vh;
    }
    .hide_mobitr{
        display: none !important;
    }
}
/* Profile Draggers */
.profiledragers{
    border-left: 1px solid #ABABAB;
    background-color: #fff;
    border-radius: 20px;
    position: fixed;
    height: 100%;
    width: 300px;
    padding-left: 80px;
    padding-top: 110px;
    overflow: auto;
}
.profiledragers h4{
    color: #C32A7C;
    font-weight: 600;
    margin-bottom: 20px;
}
.profiledragers span{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.profiledragers span img{
    width: 20px;
    margin-right: 10px;
    color: #9C9C9C;
    font-weight:500 ;
}
/* Category */
.klgcategory_main{
    border-left: 1px solid #ABABAB;
    background-color: #fff;
    border-radius: 20px;
    position: fixed;
    height: 100%;
    width: 300px;
    padding-left: 80px;
    padding-top: 110px;
    overflow: auto;
}
.klgcategory_main h4{
    color: #C32A7C;
    font-weight: 600;
}
.klgcategory_main .accordion-body{
    padding-top: 0px;
    color: #444444;
    font-weight: 500;
}
.klgcategory_main .accordion-body span{
    cursor: pointer;
    margin: 3px 0px;
    width: 90%;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.klgcategory_main .accordion-button{
    padding: 10px 0px !important;
    width: 90%;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
.klgcategory_main .accordion-button::after{
    display: none !important;
}
.klgcategory_main .accordion-button img{
    width: 30px;
    height: 30px;
    transition: 0.4s;
}
.klgcategory_main .accordion-button:not(.collapsed) img{
    transform: rotate(180deg);
}
.klgcategory_main .accordion-item{
    border: none;
}
.klgcategory_main .accordion-button:not(.collapsed){
    background-color: transparent;
    color: #000;
    font-weight: 600;
    box-shadow: none;
}
.klgcategory_main .accordion-button:focus{
    box-shadow: none !important;
}
/* End Category */

.pagestarterheadsh3 h3{
    color: #000;
    font-weight: 600;
    margin: 0px;
}
.articleformat_id{
    text-decoration: underline;
    margin-right: 10px;
}
.formatid_articleshow{
    width: 100%;
    margin: 10px 0px;
    color: #9C9C9C;
    font-weight: 600;
}
.knowledge_lister .questionskn{
    color: #1A5298;
    width: 100% !important;
    font-weight: 500;
    margin-bottom: 10px;
}
.knowledge_lister .answerkn{
    color: #4A4A4A;
    width: 100% !important;
    font-weight: 400;
    margin-bottom: 10px;
}
.span_griders span .icon_kn{
    color: #C32A7C;
    margin-right:10px;
}
.rating_spanhiders{
    border: none !important;
    padding: 0px !important;
    opacity: 1 !important;
}
.rating_spanhiders svg{
    color: #FFBD14;
}
.rating_spanhiders span{
    border: none !important;
    padding: 0px !important;
}
.span_griders span .MuiRating-icon{
    box-shadow: none !important;
}
.span_griders span:last-child{
    box-shadow: none !important;
}
.span_griders span{
    border: 1px solid #D0D0D0;
    padding: 5px 10px;
    border-radius: 8px;
    color: #000;
    font-weight: 500;
    margin: 5px 10px 5px 0px;
    box-shadow: 3px 3px 4px 0px #00000040 inset;
}
.span_griders small{
    color: #595959;
    margin-right:10px;
    font-size: medium;
}
.knowledge_lister h4{
    width: 100%;
    font-weight: 600;
    margin-bottom: 20px;
}
.top_downborders{
    border-top: 1px solid #BCBCBC;
    border-bottom: 1px solid #BCBCBC;
    padding: 10px 0px;
}
.yes_nohelper{
    border: 1px solid #D0D0D0;
    padding: 5px 20px;
    border-radius: 8px;
    color: #595959;
    font-weight: 500;
    margin: 5px 5px;
    background-color: transparent;
}
.yes_nohelper_selector{
    border: 1px solid #D0D0D0;
    padding: 5px 20px;
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
    margin: 5px 5px;
    background-color: #595959;
}
.raters_helpspan{
    color: #3A3A3A;
    font-weight: 500;
    margin-right: 10px;
}
.comment_textarea{
    background-color: transparent;
    border: 1px solid #BCBCBC;
    width: 100%;
    resize: none;
    padding: 10px 10px;
    height: 100px;
    border-radius: 8px;
    outline-color: #C32A7C;
}
.submit_btn{
    border-radius: 100px;
    background-color: #C32A7C;
    color: #fff;
    font-weight: 600;
    border: 1px solid #C32A7C;
    margin: 15px 0px;
    width: 150px;
    height: 40px;
    padding: 0px 0px;
}
.previous_comments .pink_small{
    color: #C32A7C;
    font-weight: 500;
}
.previous_comments .pink_small small{
    color: #1A5298;
    font-weight: 500;
    
}
.previous_comments .datetime_small{
    color: #9C9C9C;
    font-weight: 500;
}
.previous_comments span{
    width: 100%;
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #E7E7E7;
    border-radius: 6px;
    font-weight: 400;
}

/* Knowledge Base */
.knowledge_baseboxes{
    background: #fff;
    border: 1px solid #ACACAC;
    padding: 20px;
    border-radius: 14px;
}
.knowledge_baseboxes h4{
    color: #000;
    font-weight: 600;
    width: 100%;
}
.listers_base{
    border-top: 1px solid #ACACAC;
}
.listers_base h6{
    font-weight: 600;
}
.listers_base span{
    color: #000;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.overflowers_roots{
    height: 350px;
    overflow: auto;
}
.trashbtnroot{
    border: 1px solid #1A5298;
    color: #1A5298;
    border-radius: 5px;
    background-color: transparent;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.editbtnroot{
    border: 1px solid #1A5298;
    color: #fff;
    border-radius: 5px;
    background-color: #1A5298;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.addlister_base{
    border-top: 1px solid #ACACAC;
}
.addlister_base input{
    width: 100%;
    height: 40px;
    border: 1px solid #9C9C9C;
    background-color: transparent;
    border-radius: 6px;
    outline-color: #C32A7C;
    padding: 0px 10px;
}
.addlister_base label{
    width: 100%;
    margin-bottom: 5px;
    color: #000;
    font-weight: 500;
    margin-top: 25px;
}
.addlister_base button img{
    width: 40px;
}
.addlister_base button{
    border: 1px solid #C42B7D;
    border-radius: 100px;
    height: 40px;
    width: 125px;
    padding: 0px;
    background-color: #C42B7D;
    color: #fff;
    font-weight: 600;
    margin-top: 30px;
}
.cancel_btneditmode{
    background-color: transparent !important;
    color: #C42B7D !important;
}
.select_withsearch_ka .select__control{
    border: 1px solid #9C9C9C !important;
    padding: 0px;
}
.select_withsearch_ka .select__input-container{
    padding: 0px;
}

/* Draft */
.newadderbtn{
    background-color: #C42B7D;
    color: #fff;
    font-weight: 600;
    width: 120px;
    border-radius: 100px;
    height: 40px;
    border: none;
}
.newadderbtn .iconer{
    margin-right: 8px;
}

.darfts_inputs span{
    color: #000;
    font-weight: 500;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 30px;
}
.darfts_inputs span small{
    color: red;
    font-weight: 500;
    margin-left: 5px;
    font-size: medium;
}
.darfts_inputs input{
    color: #000;
    font-weight: 500;
    width: 100%;
    border: 1px solid #9C9C9C;
    height: 50px;
    border-radius: 5px;
    margin-bottom: 5px;
    outline-color: #C32A7C;
    padding: 0px 10px;
}
.select_draftsets select{
    color: #000 !important;
    font-weight: 500 !important;
    width: 100% !important;
    border: 1px solid #9C9C9C !important;
    height: 50px !important;
    border-radius: 5px !important;
    margin-bottom: 5px !important;
    outline: none !important;
    padding: 0px 10px !important;
    background-color: #fff !important;
}
.darfts_inputs select{
    color: #000;
    font-weight: 500;
    width: 130px;
    border: none;
    height: 40px;
    border-radius: 5px;
    outline-color: #C32A7C;
    padding: 0px 10px;
    background-color: transparent
}
.darfts_inputs textarea{
    color: #000;
    font-weight: 500;
    width: 100%;
    border: 1px solid #9C9C9C;
    /* height: 100px; */
    resize: none;
    border-radius: 5px;
    margin-bottom: 5px;
    outline-color: #C32A7C;
    padding: 0px 10px;
}
.darfts_inputs p::first-letter{
    text-transform: capitalize;
}

.darfts_inputs .date_pickermux .MuiOutlinedInput-notchedOutline{
    border: 1px solid #9C9C9C !important;
}
.darfts_inputs .date_pickermux .css-lxfshk-MuiInputBase-root-MuiOutlinedInput-root{
    height: 50px !important;
    border-radius: 5px;
    box-shadow:  none !important;
    outline: none !important;
    margin-bottom: 5px;
    background-color: #fff;
}

.darfts_inputs .select_withsearch .select__control{
    border: 1px solid #9C9C9C;
    padding: 0px 10px;
}
.darfts_inputs .select_withsearch .select__input-container{
    height: 48px;
    margin: 0px;
    padding: 0px;
}
.darfts_inputs .select_withsearch .css-hlgwow{
    padding: 0px;
}
/* Editor */
.author_spans span{
    color: #918D8D;
    font-weight: 500;
    margin-bottom: 5px;
}
.author_spans h5{
    color: #000;
    font-weight: 600;
}
.darfts_inputs .rsw-editor{
    width: 100%;
    background-color: #fff !important;
}
.darfts_inputs .rsw-ce{
    min-height: 400px;
}

.tox-statusbar__branding{
    display: none !important;
}
.tox-tinymce{
    width: 100%;
    z-index: 0;
}
.min_widthtablesets{
    min-width: 1000px !important;
}
.min_widthtablesets2{
    min-width: 800px !important;
}
.min_widthtablesets3{
    min-width: 600px !important;
}
.overflowwer_fullwidth{
    overflow: auto;
}
/* Tabs */
.drafttabs_tabs .active{
    color: #fff !important;
    border-bottom: none !important;
    font-weight: 500;
    background-color: #1A5298 !important;
}
.drafttabs_tabs{
    display: flex;
    justify-content: flex-start;
    border: 1px solid #C3C3C3 !important ;
    box-shadow: 3px 3px 4px 0px #00000040 inset;
    width: max-content !important;
    border-radius: 100px;
    font-weight: 500;
}
.drafttabs_tabs .nav-item{
    flex-grow: unset;
}
@media only screen and (min-width: 10px) and (max-width: 500px){
    .drafttabs_tabs .nav-link{
        background-color: transparent;
        color: #000 ;
        font-size: 14px;
        padding: 10px !important;
        border: none !important;
        border-radius: 100px !important;
        height: 100%;
        width: max-content !important;
    }
}
.drafttabs_tabs .nav-item button{
    background-color: transparent;
    color: #333333 ;
    font-weight: 600;
    padding: 10px 20px 10px 20px;
    border: none !important;
    border-radius: 100px !important;
    height: 100%;
    width: max-content !important;
}

/* Under Approval */
.approval_textarea label{
    font-weight: 600;
    margin-bottom: 5px;
}
.approval_textarea textarea{
    border: 1px solid #9c9c9c;
    border-radius: 5px;
    height: 200px;
    outline-color: #C32A7C;
    padding: 5px 10px;
    width: 100%;
    resize: none;
}
.history_tableheads{
    background: #E7E7E7;
    padding: 10px 10px;
}
.history_tableheads h6{
    margin-bottom: 0px;
    font-weight:600 ;
}
.history_tableans{
    padding: 15px 10px;
    border-bottom:1px solid #ABABAB ;
}
.history_tableans:last-child{
    border-bottom: none !important;
}
.history_tableans span{
    font-weight: 400;
    margin: 3px 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 90%;
}
.history_tableans span b{
    font-weight: 600;
    color: #1A5298;
}
.tablemin_height{
    min-height: 400px;
    min-width: 1300px !important;
}
.overtanblehistory{
    overflow: auto;
}




.knowledge-category .react-resizable-handle-e {
    right: 0;
    width: 3px;
    height: 100%;
    cursor: ew-resize;
    /* background-color: #ccc; */
    top: 0px;
}
.react-resizable-handle-e {
    right: 0;
    transform: rotate(0deg) !important;
}
@media only screen and (min-width: 500px){
    .dragging_cats{
        position: unset !important;
        width: 100% !important;
        height: 100vh;
    }
.resizable-container {
    display: flex;
  }
  
  .show_mobitr {
    overflow: auto;
  }
  
  .resizer {
    width: 10px; /* Wider draggable area */
    cursor: ew-resize; /* Left-right resize cursor */
    background-color: #aaa; /* A visible color to indicate draggable area */
  }
}
.react-resizable {
    position: sticky !important;
    top: 0px;
    height: 100vh;
}
 

/* Attachments */
.file_uploadericon{
    color: #959595;
    font-size: 80px;
    margin-bottom: 15px;
}
.drag_uploaders{
    background: #FFF6EA;
    border: 3px solid #A5BFDF;
    border-radius: 30px;
    text-align: center;
    padding: 30px;
    width: 100%;
}
.drag_uploaders label{
    background-color: #1A5298;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    border-radius: 100px;
    padding:8px 20px;
    width: max-content !important;
}
.attach_headerspan{
    color: #000;
    font-weight: 600;
    border-bottom: 1px solid #C8C8C8;
    padding: 10px 0px;
}
.tablelisterattach{
    border-bottom: 1px solid #C8C8C8;
    padding: 10px 0px;
}
.tablelisterattach span{
    color: #000;
    font-weight: 500;
}
.tablelisterattach .filenamesshow{
    color: #1A5298 !important;
    font-weight: 500 !important;
}
.tablelisterattach button,.tablelisterattach a{
    border: none;
    margin: 0px 10px;
    background-color: transparent;
    padding: 0px;
    color: #C42B7D;
    font-size: x-large;
}
.rename_inputer{
    border: 1px solid #1A5298;
    outline-color: #C32A7C;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #fff;
}
.change_btnattach{
    border: 1px solid #C32A7C !important;
    color: #C32A7C;
    font-weight: 600;
    font-size: medium !important;
    padding: 5px 10px !important;
    border-radius: 5px;
}




.documentcards{
    background: #FFF6EA;
    border: 1px solid #D4E6FC;
    border-radius: 10px;
    padding: 10px;
}
.documentcards img{
    width: 50px;
    height: 50px;
    padding: 5px;
}
.documentcards a{
    width: calc(100% - 60px);
    padding: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1A5298 !important;
    font-weight: 500;
}
.discurssionoverflow{
    overflow: auto;
    padding-right: 10px;
}
.dicussion_heightset{
    max-height: 300px;
}


.approvalcomment .pink_small{
    color: #C32A7C;
    font-weight: 500;
}
.approvalcomment .datetime_small{
    color: #9C9C9C;
    font-weight: 500;
}
.approvalcomment span{
    width: 100%;
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #E7E7E7;
    border-radius: 6px;
    font-weight: 400;
}
.apporovehistoryscoll{
    max-height: 500px;
    overflow: auto;
}

/* Compare modal */
.compare_artibtns select{
    color: #000;
    width: 150px;
    height: 40px;
    border: 1px solid #9F9C9D;
    font-weight: 500;
    padding: 0px 7px;
    border-radius: 5px;
    outline-color: #C32A7C;
}
.compare_artibtns button{
    background-color: transparent;
    color: #C42B7D;
    border: 1px solid #C42B7D;
    border-radius: 5px;
    padding: 0px 15px;
    font-weight: 600;
}
.modal_comparecontent{
    height: 80vh;
    overflow: auto;
}
.costom_modalcompare_overflowers{
    overflow: auto;
}
.costom_modalcompare{
    min-width: 1200px !important;
}
.back_changecols{
    background-color:#c9e3ff ;
}

.blue_primarymodalbtn1{
    background-color: #fff;
    color:#C32A7C;
    border: 2px solid #C32A7C;
    border-radius: 100px;
    font-weight: 600;
    height: 40px;
    padding: 0px 20px;
}
.blue_primarymodalbtn2{
    background-color: #C32A7C;
    color:#fff;
    border: 2px solid #C32A7C;
    border-radius: 100px;
    font-weight: 600;
    height: 40px;
    padding: 0px 20px;
}
.first_child_changes:first-child{
    display: inline-block;
}
.first_child_changes p{
    margin: 0px;
}


.changed_artical {
    background-color: yellow;
    font-weight: bold;
  }
  
  .artical_strike {
    text-decoration: line-through;
    color: red;
  }