.cursorpointgrid{
    cursor: pointer;
}
.cursorpointgrid b{
    transition: 0.2s;
}
.cursorpointgrid:hover b{
    color: #1a5298;
    text-decoration: underline;
}
.flow_headermain{
    background-color: #fff;
    padding: 20px 20px;
    box-shadow: 0px 4px 8px 0px #00000040;
    position: fixed;
    top: 0px;
    z-index: 999;
}
.flow_headermain .logosign{
    width: 145px;
    cursor: pointer;
}
.fake_profile{
    width: 40px;
    height: 40px;
    border-radius: 100px;
    margin-right:10px;
}
.content_container{
    width: 100%;
    padding: 110px 20px 20px 85px;
    background-color: #FFF6EA !important;
    min-height: 100vh;
}
.content_container_validate{
    width: 100%;
    padding: 110px 20px 20px 410px;
    background-color: #FFF6EA !important;
    min-height: 100vh;
}
.validate_dragers{
    border-left: 1px solid #ABABAB;
    background-color: #fff;
    border-radius: 20px;
    position: fixed;
    height: 100%;
    width: 390px;
    padding-left: 80px;
    padding-right: 20px;
    padding-top: 110px;
    overflow: auto;
}
.validate_dragers h4{
    color: #C32A7C;
    font-weight: 600;
    margin-bottom: 20px;
}
.validate_dragers h5{
    color: #C32A7C;
    font-weight: 600;
    margin-bottom: 20px;
}
.validate_dragers span ,.validate_dragers a{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    color: #000;
    text-decoration: none;
}
.validate_dragers .span_disabledrager{
    color: #9F9F9F;
    cursor:not-allowed;
}
.validate_dragers span img,.validate_dragers a img{
    width: 20px;
    margin-right: 10px;
    color: #9C9C9C;
    font-weight:500 ;
}
.sidebarfixed{
    background-color: #C42B7D;
    box-shadow: 8px 4px 20px 0px #00000040;
    min-height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 65px;
    padding-top:110px !important;
    z-index: 8;
}
.sidebarfixed .sidelinks{
    position: relative;
    z-index: 99;
    margin: 5px 0px;
    cursor: pointer;
}
.active_side .inner_layer img{
    filter: brightness(100%) invert(0) !important;
}
.active_side .inner_layer{
    background-color: #fff !important;
}
.sidebarfixed .sidelinks .inner_layer{
    display: flex;
    justify-content: center;
    padding: 7px 0px;
    transition: 0.4s ;
    background-color: #C42B7D;
    z-index: 2 !important;
}
.sidebarfixed .sidelinks span{
    position: absolute;
    z-index: -1;
    left: -200px;
    top: 0px;
    height: 100%;
    width: max-content !important;
    background-color: #fff;
    color: #C42B7D;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 0px 100px 100px 0px;
    transition: 0.4s;
}
.sidebarfixed .sidelinks:hover span{
    left: 60px;
}
.sidebarfixed .sidelinks:hover .inner_layer{
    background-color: #fff;
}
.sidebarfixed .sidelinks img{
    width: 30px;
    padding: 3px;
    border-bottom: 1px solid #fff;
    -webkit-filter: grayscale(100%);
    filter: brightness(0) invert(1);
    transition: 0.4s ease-in;
}
.sidebarfixed .sidelinks:hover img{
    filter: brightness(100%) invert(0);
    transition: 0.4s ease-in;
}
.menubtn_mobile{
    display: none;
}
.sidebarfixed .sidelinks:hover .list_showers{
    display: block !important;
}
.list_showers{
    display: none;
    background-color: #fff;
    min-width: 250px;
    width: 100% !important;
    position: absolute !important;
    left: 5px;
    top: 40px;
    padding: 10px 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 12px 0px #0000002E;
    z-index: -1 !important;
}
.list_showers small{
    font-size: small;
    padding: 10px 10px;
    border-bottom: 1px solid #C5C5C5;
    color: #595959;
}
.list_showers small:last-child{
    border-bottom: none !important;
}
.list_showers .activesmallersb{
    color: #C42B7D !important;
}
 .setheaderprofile button.show{
    background-color: #DEECFF !important;
    color: #000 !important;
}
@media only screen and (min-width: 10px) and (max-width: 500px){
    .content_container, .content_container_validate{
        width: 100%;
        padding: 70px 10px 20px 10px;
    }
    .validate_dragers{
        padding-left: 30px !important;
        padding-top: 70px !important;
        width: 100%;
    }
    .dropdown_notify_widthset{
        min-width: 315px !important;
    }
    .menubtn_mobile{
        display: block;
        background-color: transparent;
        color: #C42B7D;
        border: none ;
    }
    .flow_headermain{
        padding: 10px;
    }
    .flow_headermain .logosign{
        width: 100px;
    }
    .flow_headermain .setheaderprofile span{
        display: none;
    }
    .sidebarfixed{
        background-color: #C42B7D;
        box-shadow: 8px 4px 20px 0px #00000040;
        height: 100vh;
        position: fixed;
        top: 0px;
        left: -150%;
        width: 100% !important;
        padding-top:110px !important;
        padding-bottom: 20px;
        transition: 0.4s;
        overflow: auto !important;
        z-index: 9;
    }
    .activeside{
        left: 0%;
    }
    .sidebarfixed .sidelinks span{
        position: unset !important;
        z-index: 99;
        background-color: transparent !important;
        box-shadow: none;
        color: #fff !important;
    }
    .active_side .inner_layer span{
        color: #C42B7D !important;
    }
    .sidebarfixed .sidelinks:hover span{
        color:#C42B7D !important;
    }
    .sidebarfixed .sidelinks .inner_layer {
        display: flex;
        justify-content: flex-start;
        padding: 7px 0px;
        padding-left: 15%;
        transition: 0.4s;
        background-color: #C42B7D;
        z-index: 2 !important;
        gap: 10px;
    }
}
.drop_btnlogout{
    background-color: transparent !important;
    color: #212527 !important;
    border: none !important;
}
.drop_btnlogout:hover{
    color: #C42B7D !important;
}

/* .sidebaraccords */

.sidebaraccords .accordion-body{
    padding: 10px 0px;
    margin-top: 10px;
    color: #444444;
    font-weight: 500;
    background-color: #FFF6EA;
    border-radius: 10px;
}
.sidebaraccords .accordion-body small{
    cursor: pointer;
    margin: 0px 0px !important;
    padding: 10px !important;
    display: flex;
    align-items: center;
}
.sidebaraccords .accordion-button{
    padding: 0px 0px !important;
    font-size: small;
    color: #595959;
    font-weight: 500 !important;
}
.sidebaraccords .active_accordsmall{
    color: #C42B7D !important;
}
.sidebaraccords .accordion-item{
    border: none;
}
.sidebaraccords .accordion-button:not(.collapsed){
    background-color: transparent;
    color: #000;
    font-weight: 600;
    box-shadow: none;
}
.sidebaraccords .accordion-button:focus{
    box-shadow: none !important;
}
.sidebaraccords .icons{
    margin: 0px 10px;
    font-size: medium;
}

.notification_btn{
    background-color: transparent;
    color: #212527;
    margin-right: 15px;
    border: none;
    font-size: x-large;
    padding: 0px;
    position: relative;
}
.notiafterset::before{
    content: "";
    position: absolute;
    border-radius: 100px;
    width: 10px;
    height: 10px;
    background-color: #C42B7D;
    right: 0px;
}
.dropdown_notify_widthset{
    min-width: 615px;
}
.notification_btn.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
    background-color: transparent !important;
    color: #000 !important;
}
.notification_btn.btn:hover{
    background-color: transparent !important;
    color: #000 !important;
}
.notification_btn::after{
    display: none !important;
}
.notify_viewmore{
    font-size: small;
    background-color: transparent;
    border: none;
    padding: 0px !important;
    color: #C42B7D;
    font-weight: 600;
}
.datewithshows{
    padding: 0px 10px;
    text-align: center;
    font-weight: 600;
    color: #626262;
    background-color: #F8F8F8;
    z-index: 3 !important;
}
.forbackliners::after{
    content: "";
    height: 1px;
    width: 100%;
    background-color: #ACACAC;
    position: absolute;
    left: 0px;
    top: 10px;
    z-index: 1;
}
.forbackliners{
    position: relative;
}