.grid_requestcatelog{
    background-color: #fff;
    box-shadow: 0px 2px 12px 0px #0000002E;
    padding: 20px;
    border-radius: 20px;
}
.grid_requestcatelog .icons_fa{
    color: #C42B7D;
    width: max-content;
    font-size: xx-large;
}
.grid_requestcatelog h6{
    margin-top: 20px;
    color: #000;
    font-weight: 600;
}
.grid_requestcatelog span{
    margin: 10px 0px;
    color: #626262;
    text-align: justify;
    padding-bottom: 10px;
}
.grid_requestcatelog small{
    color: #C42B7D;
    font-size: medium;
    font-weight: 600;
    cursor: pointer;
    width: max-content;
}
.request_user_edit{
    border-top: 1px solid #ADADAD;
}
.request_user_edit img{
    width: 100%;
}
.requestedit_quat h3{
    color: #C42B7D;
    font-weight: 600;
}
.requestedit_quat span{
    color: #6D6D6D;
    font-weight: 500;
    margin: 10px 0px;
}
.catalog_caregoryspans span{
    padding: 10px !important;
    color: #000000;
    font-weight: 500;
    border-radius: 8px;
    cursor: pointer;
}
.active_catalog{
    background: #FFE8F4;
    font-weight: 600 !important;
}

/* Task status */
.stautuspending{
    color: #E50000;
    font-weight: 500;
}
.stautusclosed{
    color: #22AE00;
    font-weight: 500;
}
.stautusprocess{
    color: #1A5298;
    font-weight: 500;
}
.link_datatspan{
    color: #1A5298;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}
.changedbackcol{
    background: #FFEECC !important;
}

.spanreport_elipse{
    margin: 0px !important;
    width: 200px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}
.reportincident{
    border: none !important;
}
.pinkbgattachreport{
    background-color: #F6F6F6;
    border: 1px solid #D8D8D8;
    padding: 3px 10px;
    border-radius: 5px;
}