.tempdashboard_main img{
    width: 100%;
}
.tempdashboard_main h5{
    text-align: center;
    font-weight: 400;
}
.main_dashblog{
    background-color: #fff;
    box-shadow: 0px 0px 12px 0px #00000033;
    padding: 15px 15px !important;
    border-radius: 15px;
    position: relative;
}
.main_dashblog img{
    border-radius: 7.5px;
}
.main_dashblog h5{
    font-weight: 600;
    color: #1A5298;
    margin-top: 10px;
    text-align: left;
}
.main_dashblog p{
    color: #676767;
    font-weight: 400;
    margin: 0px;
}
.main_dashblog .readmore_btn{
    color: #C32A7C;
    font-weight: 600;
    background-color: transparent;
    border: none !important;
    padding: 0px;
}
.dates_abstop{
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #D0D0D0;
    padding: 3px 10px ;
    font-weight: 600;
    position: absolute;
    right: 0px;
    top: 0px;
}
.dates_abstop .icon_tempdash{
    color: #C32A7C;
    margin-right: 10px;
}