.main_loginbg{
    background: #FFF6EA;
    min-height: 100vh;
    padding: 30px;
}
.leniarrightlogin{
    background: linear-gradient(180deg, #C32A7C 0%, #1A5298 100%);
    border-radius: 30px;
    padding: 0px 20px;
    padding-top: 20px;
    height: max-content;
}
.leniarrightlogin img{
    width: 75%;
}
.leniarrightlogin h1{
    color: #fff;
    font-weight: 600;
    text-align: center;
}
.leniarrightlogin h5{
    color: #fff;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;
}
.left_inputlogin{
    background-color: #fff;
    border-radius: 30px;
    padding: 30px 30px;
    box-shadow: 0px 2px 16px 0px #0000002E;
}
.left_inputlogin img{
    width: 60px;
    margin-bottom: 20px;
    cursor: pointer;
}
.left_inputlogin h2{
    color: #000;
    font-weight: 600;
}
.left_inputlogin span{
    color: #000;
    font-weight: 500;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 20px;
}
.left_inputlogin input{
    color: #000;
    font-weight: 500;
    width: 100%;
    border: 1px solid #9C9C9C;
    height: 40px;
    border-radius: 5px;
    margin-bottom: 5px;
    outline-color: #C32A7C;
    padding: 0px 10px;
}
.left_inputlogin .input_witheye{
    color: #000;
    font-weight: 500;
    width: 100% !important;
    border: 1px solid #9C9C9C;
    height: 40px;
    border-radius: 5px;
    margin-bottom: 5px;
    outline-color: #C32A7C;
    padding: 0px 0px;
}
.left_inputlogin .input_witheye input{
    color: #000;
    font-weight: 500;
    width: 80% !important;
    border: none;
    background-color: transparent !important;
    height: 40px;
    border-radius: 5px;
    margin-bottom: 5px;
    outline-color: #C32A7C;
    padding: 0px 10px;
}
.input_witheye button{
    width: 20% !important;
    height: 40px !important;
    border: none !important;
    border-radius: 0px !important;
    background-color: transparent !important;
    color:#7F7F7F !important;
}
.left_inputlogin a{
    text-align: end;
    color: #C32A7C;
    text-decoration: none;
    font-weight:600 ;
    margin-bottom: 20px;
}
.left_inputlogin button{
    width: 100%;
    text-align: center;
    background-color: #C32A7C;
    border: 1px solid #C32A7C;
    color: #fff;
    font-weight:600 ;
    margin-bottom: 20px;
    border-radius: 100px;
    height: 50px;
}
.left_inputlogin button img{
    width: 45px;
    margin: 0px !important;
}
.resend_otpsmall img{
    margin-bottom: 0px !important;
    width: 40px;
}
.resend_otpsmall{
    text-align: end !important;
    width: max-content !important;
    height: 40px;
    margin-bottom: 0px !important;
    display: flex;
    justify-content: end;
    align-items: center;
    color:#C32A7C ;
    font-weight: 600 !important;
    cursor: pointer;
}
.resend_otpsmallTimer{
    text-align: end !important;
    width: max-content !important;
    height: 40px;
    margin-bottom: 0px !important;
    display: flex;
    justify-content: end;
    align-items: center;
    color:#1A5298 ;
    font-weight: 600 !important;
}
.left_inputlogin small{
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
    font-weight: 400;
}
.login_small1 a{
    text-decoration: underline;
    color: #000;
}
.login_small2 a{
    text-decoration: underline;
    color: #C32A7C;
}
.left_buttlogin{
    padding: 30px 30px;
}
.left_buttlogin h2{
    color: #000;
    font-weight: 600;
    width: 100%;
    margin-bottom: 30px;
}
.left_buttlogin img{
    width: 60px;
    margin-bottom: 30px;
}
.left_buttlogin button{
    height: 150px;
    width: 100%;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    border: 2px solid #AEAEAE;
    box-shadow: 0px 2px 16px 0px #0000001F;
}
.left_buttlogin button img{
    width: 25% !important;
    margin-bottom: 15px;
}
.can_selectbtn button{
    border: 2px solid #C32A7C;
}
@media only screen and (min-width: 10px) and (max-width: 500px){
    .left_buttlogin{
        margin: 20px 0px;
        padding: 20px;
    }
    .left_inputlogin{
        margin: 20px 0px;
        padding: 20px;
    }
    .leniarrightlogin{
        border-radius:0px;
    }
    .main_loginbg{
        padding: 0px 0px;
    }
    .regisdetailwhite{
        padding: 20px !important;
    }
}

/* Register Details */
.registerdetailsmain{
    background: #FFF6EA;
    padding: 30px 0px;
    min-height: 100vh;
}
.regisdetailwhite{
    background: #FFFFFF;
    box-shadow: 0px 2px 16px 0px #0000002E;
    border-radius: 30px;
    padding: 30px;
}
.regisdetailwhite img{
    width: 155px;
    margin-bottom: 30px;
    cursor: pointer;
}
.regisdetailwhite h2{
    margin-bottom: 30px;
    width: 100%;
    text-align: center;
    font-weight: 600;
}
.progressspan {
    position: relative;
}
.progressspan::after{
    content: "";
    width: 100%;
    height: 1px;
    border: 1px dashed #C32A7C;
    position: absolute;
}
.progressspan span{
    background-color: #fff;
    width: 50px;
    height: 50px;
    font-size: larger;
    color: #C32A7C;
    border: 1px solid #C32A7C;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    z-index: 99;
}
.activeprogress{
    color: #fff !important;
    background-color: #C32A7C !important;
}
.regiter_inputs h5{
    width: 100%;
    color: #C32A7C;
    font-weight: 600;
    margin: 30px 0px;
}
.regiter_inputs span{
    color: #000;
    font-weight: 500;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 20px;
}
.regiter_inputs input,.regiter_inputs select{
    color: #000;
    font-weight: 500;
    width: 100%;
    border: 1px solid #9C9C9C;
    height: 40px;
    border-radius: 5px;
    margin-bottom: 5px;
    outline-color: #C32A7C;
    padding: 0px 10px;
}
.regiter_inputs textarea{
    color: #000;
    font-weight: 500;
    width: 100%;
    border: 1px solid #9C9C9C;
    /* height: 100px; */
    resize: none;
    border-radius: 5px;
    margin-bottom: 5px;
    outline-color: #C32A7C;
    padding: 0px 10px;
}
.regiter_inputs .input_witheye input{
    color: #000;
    font-weight: 500;
    width: 80% !important;
    border: none;
    background-color: transparent !important;
    height: 40px;
    border-radius: 5px;
    margin-bottom: 5px;
    outline-color: #C32A7C;
    padding: 0px 10px;
}
.regiter_inputs .form-control{
    padding: 7px 10px;
    outline-color: #C32A7C;
    box-shadow: none;
}
.regiter_inputs .input_witheye button{
    width: 20% !important;
    height: 40px !important;
    border: none !important;
    border-radius: 0px !important;
    background-color: transparent !important;
    color:#7F7F7F !important;
}
.regiter_inputs .input_witheye{
    color: #000;
    font-weight: 500;
    width: 100% !important;
    border: 1px solid #9C9C9C;
    height: 40px;
    border-radius: 5px;
    margin-bottom: 5px;
    outline-color: #C32A7C;
    padding: 0px 0px;
}
.regiter_inputs p{
    text-transform: capitalize;
}
.registersendbtn1{
    background-color: transparent;
    height: 40px;
    border: 1px solid #C32A7C;
    color: #C32A7C;
    font-weight: 600;
    border-radius: 100px;
    width: 145px;
    margin: 30px 0px;
}
.registersendbtn2{
    background-color: #C32A7C;
    height: 40px;
    border: 1px solid #C32A7C;
    color: #fff;
    font-weight: 600;
    border-radius: 100px;
    width: 145px;
    margin: 30px 0px;
}
.themebuttonoverwidth1{
    background-color: transparent;
    height: 40px;
    border: 1px solid #C32A7C;
    color: #C32A7C;
    font-weight: 600;
    border-radius: 100px;
    width: max-content;
    margin: 30px 0px;
    padding: 0px 30px;
}
.themebuttonoverwidth2{
    background-color: #C32A7C;
    height: 40px;
    border: 1px solid #C32A7C;
    color: #fff;
    font-weight: 600;
    border-radius: 100px;
    width: max-content;
    padding: 0px 30px;
    margin: 30px 0px;
}
.registersendbtn2 img, .registersendbtn1 img{
    margin: 0px !important;
    width: 35px !important;
}
.main_radioregister input{
    width: 15px;
    height: 15px;
    margin: 10px 10px;
}
.main_radioregister label{
    font-weight: 600;
    margin-right: 20px;
}









.fileUploadInput {
    position: relative;
    z-index: 1; 
    color: #000;
    font-weight: 500;
    width: 100% !important;
    border: 1px solid #9C9C9C;
    height: 40px;
    border-radius: 5px;
    margin-bottom: 5px;
    outline-color: #C32A7C;
    padding: 0px 0px;
    overflow: hidden;
}
      
.fileUploadInput input {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 40px;
    color: #000;
    font-weight: 500;
    border: none;
    background-color: transparent !important;
    outline-color: #C32A7C;
    cursor: pointer;
}
    
.fileUploadInput input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
    margin-left: 10px;
    padding: 0;
    height: 40px;
    width: 0; 
}
          
    .fileUploadInput label {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 90px;
        height: 40px;
        color: white;
        border: none;
        background-color: #1A5298;
        border-radius: 5px;
        font-weight: 600; 
        z-index: 99;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
.verified_small{
    color: green;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: larger;
}
.date_caps{
    text-transform: uppercase;
}
.otp_input{
    letter-spacing: 10px;
}
/* Register modal */
.modal_register img{
    width: 25%;
}
.modal_register h3{
    color: #C32A7C;
    font-weight: 600;
    margin: 20px 0px;
}
.modal_register small{
    color: #000;
    font-weight: 500;
    margin-bottom: 20px;
}
.modal_register button{
    width: 100%;
    background-color: #C32A7C;
    color: #fff;
    border-radius: 100px;
    height: 40px;
    font-weight: 600;
    border: 1px solid #C32A7C;
    margin-bottom: 10px;
}

/* Date picker x */
.date_pickermux svg{
    color: #C32A7C;
}
.date_pickermux .MuiOutlinedInput-notchedOutline{
    border: 1px solid #9C9C9C !important;
}
.date_pickermux .css-lxfshk-MuiInputBase-root-MuiOutlinedInput-root{
    height: 40px !important;
    border-radius: 5px;
    box-shadow:  none !important;
    outline: none !important;
    margin-bottom: 5px;
}
.date_pickermux input{
    border: 0px solid #9C9C9C !important;
    margin: 0px !important;
    text-transform: uppercase;
}
.select_withsearch .select__control{
    border: 1px solid #9C9C9C;
    padding: 0px 10px;
}
.select_withsearch .select__input-container{
    height: 38px;
    margin: 0px;
    padding: 0px;
}
.select_withsearch .css-hlgwow{
    padding: 0px;
}
.main_tickiconpopup{
    font-size: 90px;
    color: #fff;
    background-color: #45E755;
    border-radius: 100px;
    padding: 3px;
}